import { permission } from '@/directives/permission';
import { type App } from 'vue';
import { usePage } from '@inertiajs/vue3';

export default {
  install(app: App) {
    // Register the v-permission directive
    app.directive('permission', permission);
    
    // Add global methods to check permissions and roles
    app.config.globalProperties.$can = (permissionName: string): boolean => {
      const auth = usePage().props.auth as any;
      return (auth.permissions || []).includes(permissionName);
    };
    
    app.config.globalProperties.$hasRole = (roleName: string): boolean => {
      const auth = usePage().props.auth as any;
      return (auth.roles || []).includes(roleName);
    };
  }
}; 