import { usePage } from '@inertiajs/vue3';
import type { Directive, DirectiveBinding } from 'vue';

/**
 * Usage:
 * v-permission="'view users'"
 * v-permission:role="'admin'"
 */
export const permission: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const page = usePage();
    const auth = page.props.auth as any;
    
    const permissions = auth.permissions || [];
    const roles = auth.roles || [];
    const value = binding.value;
    const arg = binding.arg || 'permission';
    
    let hasAccess = false;
    
    if (arg === 'role') {
      // Check if the user has the required role
      hasAccess = roles.includes(value);
    } else {
      // Default to checking permissions
      hasAccess = permissions.includes(value);
    }
    
    if (!hasAccess) {
      // Remove the element from the DOM
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  }
};

export default permission; 