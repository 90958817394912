import '../css/app.css';

import { createInertiaApp } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import { replayIntegration } from '@sentry/vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import type { DefineComponent } from 'vue';
import { createApp, h } from 'vue';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { initializeTheme } from './composables/useAppearance';
import { SENTRY_ATTACH_STACKTRACE, SENTRY_DSN, SENTRY_ENABLED, SENTRY_ENVIRONMENT, SENTRY_PROFILES_SAMPLE_RATE, SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, SENTRY_REPLAYS_SESSION_SAMPLE_RATE, SENTRY_TRACES_SAMPLE_RATE } from './config/sentry';
import { inertiaRoutingInstrumentation } from './lib/inertia-router-sentry-instrumentation';
import permissionsPlugin from './plugins/permissions';

// Use type augmentation for vite
declare global {
    interface ImportMeta {
        readonly env: Record<string, string>;
        readonly glob: <T>(pattern: string) => Record<string, () => Promise<T>>;
    }
}

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob<DefineComponent>('./pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: SENTRY_DSN,
            tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
            profilesSampleRate: SENTRY_PROFILES_SAMPLE_RATE,
            replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
            replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
            integrations: [
                inertiaRoutingInstrumentation(),
                replayIntegration(),
            ],
            // Enable error tracking
            enabled: SENTRY_ENABLED,
            // Set environment
            environment: SENTRY_ENVIRONMENT,
            // Capture unhandled rejections
            attachStacktrace: SENTRY_ATTACH_STACKTRACE,
        });

        app.use(plugin)
           .use(ZiggyVue)
           .use(permissionsPlugin)
           .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});

// This will set light / dark mode on page load...
initializeTheme();
