import { router } from '@inertiajs/vue3';
import {
    captureException,
    getActiveSpan,
    getCurrentScope,
    browserTracingIntegration as originalBrowserTracingIntegration,
    SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN,
    SEMANTIC_ATTRIBUTE_SENTRY_SOURCE,
    spanToJSON,
    startBrowserTracingNavigationSpan,
    updateSpanName,
} from '@sentry/browser';
import type { Integration, SpanAttributes, StartSpanOptions, TransactionSource } from '@sentry/core';

function instrumentInertiaRouter(options: { instrumentNavigation: boolean }, startNavigationSpan: (options: StartSpanOptions) => void) {
    router.on('exception', (event) => {
        captureException(event.detail.exception);
    });

    router.on('before', (event) => {
        if (!options.instrumentNavigation) {
            return;
        }

        const { visit } = event.detail;

        if (visit.prefetch) {
            return;
        }

        const spanName = visit.url.pathname;
        const transactionSource: TransactionSource = 'route';

        const attributes: SpanAttributes = {
            [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.navigation.vue-inertia',
            [SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: transactionSource,
            'inertia.visit.method': visit.method,
            'inertia.visit.preserveScroll': visit.preserveScroll ? 'true' : 'false',
            'inertia.visit.preserveState': visit.preserveState ? 'true' : 'false',
            'inertia.visit.replace': visit.replace ? 'true' : 'false',
            'inertia.visit.only': visit.only,
            'inertia.visit.except': visit.except,
            'inertia.visit.async': visit.async ? 'true' : 'false',
            'inertia.visit.fresh': visit.fresh ? 'true' : 'false',
            'inertia.visit.preserveUrl': visit.preserveUrl ? 'true' : 'false',
            'inertia.visit.completed': visit.completed ? 'true' : 'false',
            'inertia.visit.cancelled': visit.cancelled ? 'true' : 'false',
            'inertia.visit.interrupted': visit.interrupted ? 'true' : 'false',
        };

        getCurrentScope().setTransactionName(spanName);

        // Add query parameters to attributes
        const searchParams = new URLSearchParams(visit.url.search);
        for (const [key, value] of searchParams.entries()) {
            attributes[`query.${key}`] = value;
        }

        startNavigationSpan({
            name: spanName,
            op: 'navigation',
            attributes,
        });
    });

    router.on('finish', (event) => {
        const { visit } = event.detail;

        if (visit.prefetch) {
            return;
        }

        const span = getActiveSpan();

        if (!span) {
            return;
        }

        const op = spanToJSON(span).op;

        if (op !== 'navigation') {
            return;
        }

        const { pathname } = event.detail.visit.url;

        updateSpanName(span, pathname);
    });
}

export const inertiaRoutingInstrumentation = (options: Parameters<typeof originalBrowserTracingIntegration>[0] = {}): Integration => {
    const browserTracingIntegrationInstance = originalBrowserTracingIntegration({
        ...options,
        instrumentNavigation: false,
    });

    const { instrumentNavigation = true } = options;

    return {
        ...browserTracingIntegrationInstance,
        afterAllSetup: (client) => {
            browserTracingIntegrationInstance.afterAllSetup?.(client);

            const startNavigationSpan = (options: StartSpanOptions): void => {
                startBrowserTracingNavigationSpan(client, options);
            };
            instrumentInertiaRouter({ instrumentNavigation }, startNavigationSpan);
        },
    };
};
